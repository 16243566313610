/* Faqws.css */
.custom-scroll::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Make scrollbar background transparent */
  }
  
  .custom-scroll {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  